<template>
  <div>
    <RWCard title="Create Contact">
      <template #body>
        <p>
          Create a new contact using the form below. Once created the new
          contact, you can apply it by using the option
          <b>"Change Contacts"</b> for this and for any other domains you have
          with us.
        </p>
        <form @submit.stop.prevent="on_submit">
          <div class="form-group row mb-3">
            <label for="" class="col-sm-3 col-form-label">First Name</label>
            <div class="col-sm-9">
              <b-form-input
                type="text"
                v-model.trim="$v.form.fname.$model"
                :state="validateState('fname')"
              ></b-form-input>
            </div>
          </div>
          <div class="form-group row mb-3">
            <label for="" class="col-sm-3 col-form-label">Last Name</label>
            <div class="col-sm-9">
              <b-form-input
                type="text"
                v-model.trim="$v.form.lname.$model"
                :state="validateState('lname')"
              ></b-form-input>
            </div>
          </div>
          <div class="form-group row mb-3">
            <label for="" class="col-sm-3 col-form-label">Company Name</label>
            <div class="col-sm-9">
              <b-form-input
                type="text"
                v-model.trim="$v.form.company.$model"
                :state="validateState('company')"
              ></b-form-input>
            </div>
          </div>
          <div class="form-group row mb-3">
            <label for="" class="col-sm-3 col-form-label">Email Address</label>
            <div class="col-sm-9">
              <b-form-input
                type="text"
                v-model.trim="$v.form.email.$model"
                :state="validateState('email')"
              ></b-form-input>
            </div>
          </div>
          <div class="form-group row mb-3">
            <label for="" class="col-sm-3 col-form-label">Address 1</label>
            <div class="col-sm-9">
              <b-form-input
                type="text"
                v-model.trim="$v.form.address.$model"
                :state="validateState('address')"
              ></b-form-input>
            </div>
          </div>
          <div class="form-group row mb-3">
            <label for="" class="col-sm-3 col-form-label">Address 2</label>
            <div class="col-sm-9">
              <b-form-input
                type="text"
                v-model.trim="$v.form.address2.$model"
                :state="validateState('address2')"
              ></b-form-input>
            </div>
          </div>
          <div class="form-group row mb-3">
            <label for="" class="col-sm-3 col-form-label">Address 3</label>
            <div class="col-sm-9">
              <b-form-input
                type="text"
                v-model.trim="$v.form.address3.$model"
                :state="validateState('address3')"
              ></b-form-input>
            </div>
          </div>
          <div class="form-group row mb-3">
            <label for="" class="col-sm-3 col-form-label">Zip Code</label>
            <div class="col-sm-9">
              <b-form-input
                type="text"
                v-model.trim="$v.form.postal_code.$model"
                :state="validateState('postal_code')"
              ></b-form-input>
            </div>
          </div>
          <div class="form-group row mb-3">
            <label for="" class="col-sm-3 col-form-label">City</label>
            <div class="col-sm-9">
              <b-form-input
                type="text"
                v-model.trim="$v.form.city.$model"
                :state="validateState('city')"
              ></b-form-input>
            </div>
          </div>
          <div class="form-group row mb-3">
            <label for="" class="col-sm-3 col-form-label">State/Region</label>
            <div class="col-sm-9">
              <b-form-input
                type="text"
                v-model.trim="$v.form.province.$model"
                :state="validateState('province')"
              ></b-form-input>
            </div>
          </div>
          <div class="form-group row mb-3">
            <label for="" class="col-sm-3 col-form-label">Country</label>
            <div class="col-sm-9">
              <b-form-select
                v-model="form.country"
                :options="negara"
                :state="validateState('country')"
              ></b-form-select>
            </div>
          </div>
          <div class="form-group row mb-3">
            <label for="" class="col-sm-3 col-form-label">Phone Number</label>
            <div class="col-sm-3 col-5">
              <b-input-group class="mb-2 mr-sm-2 mb-sm-0">
                <template #prepend>
                  <b-input-group-text
                    ><i class="fa fa-plus"></i
                  ></b-input-group-text>
                </template>
                <b-form-input
                  type="number"
                  v-model.trim="$v.form.phone_cc.$model"
                  :state="validateState('phone_cc')"
                ></b-form-input>
              </b-input-group>
            </div>
            <div class="col-sm-6 col-7">
              <b-form-input
                type="number"
                v-model.trim="$v.form.phone.$model"
                :state="validateState('phone')"
              ></b-form-input>
            </div>
          </div>

          <div class="form-group row mb-3">
            <div class="col-sm-9 offset-sm-3">
              <button class="btn btn-success">Create New Contact</button>
            </div>
          </div>
        </form>
      </template>
    </RWCard>
  </div>
</template>

<script>
import country from "@/views/pages/product/domain/management/contact/actions/country.json";
import RWCard from "@/views/partials/content/Portlet.vue";
import { loading, ApiURL, notif } from "@/common/mixins/general.js";
import { contactMixin } from "@/common/mixins/product/domain/contact-mixin.js";
import { validationMixin } from "vuelidate";
import { email, minLength, required } from "vuelidate/lib/validators";

export default {
  mixins: [contactMixin, validationMixin, loading, ApiURL, notif],
  components: { RWCard },
  props: {
    DomainID: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      selected: {
        country: ""
      },
      negara: country,
      form: {
        fname: "",
        lname: "",
        email: "",
        company: "",
        address: "",
        address2: "",
        address3: "",
        city: "",
        province: "",
        phone_cc: "62",
        phone: "",
        fax_cc: "",
        fax: "",
        country: 96,
        postal_code: ""
      }
    };
  },
  validations: {
    form: {
      fax: { minLength: minLength(1) },
      city: { required, minLength: minLength(3) },
      fname: { required, minLength: minLength(3) },
      lname: { minLength: minLength(2) },
      email: { required, email, minLength: minLength(5) },
      phone: { required },
      fax_cc: { minLength: minLength(1) },
      company: { required, minLength: minLength(2) },
      address: { required, minLength: minLength(5) },
      country: { required },
      address2: { minLength: minLength(5) },
      address3: { minLength: minLength(5) },
      province: { required },
      phone_cc: { minLength: minLength(1) },
      postal_code: { required, minLength: minLength(5) }
    }
  },
  methods: {
    validateState(name) {
      const { $dirty, $error } = this.$v.form[name];
      return $dirty ? !$error : null;
    },

    /**
     * ---------------------------------------------------
     * proses create contact whois
     * ---------------------------------------------------
     */
    on_submit: function() {
      this.$v.form.$touch();
      if (this.$v.form.$anyError) {
        return;
      }

      this.mx_loading();
      this.xhr_domain_post("contact", this.form, this.DomainID)
        .then(({ data }) => {
          this.mx_swal(data.message, "", "success");
          this.$emit("changeAction", "Change Contact");
        })
        .catch(({ response }) => {
          this.mx_swal(response.data.message);
        })
        .finally(() => {
          this.mx_loading(false);
        });
    }
  }
};
</script>
