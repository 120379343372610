/**
 * -------------------------------------------
 * function global untuk dns management
 * -------------------------------------------
 */

import {
  changeNameDnsComponent,
  sendDNSforEdit
} from "@/store/rw/product.domain.module.js";
export const dnsMixin = {
  methods: {
    /**
     * -------------------------------------------
     * menentukan view dinamis by action
     * -------------------------------------------
     */
    change_component(name) {
      this.$store.dispatch(changeNameDnsComponent, name);
    },

    /**
     * ------------------------------------------
     * kirim data ke vuex
     * ------------------------------------------
     */
    send_DNS_edit(params) {
      const obj = typeof params === "object" ? params : {};
      this.$store.dispatch(sendDNSforEdit, obj);
    }
  }
};
