<template>
  <div>
    <RWCard title="Change Contact">
      <template #body>
        <p>
          Select an existent contact to use for any of the 4 contact type reg-c,
          admin-c, tech-c and bill-c for this domain. By default, there exist
          only one Contact which has been created for you using your Customer
          details.
        </p>
        <p>
          If you wish to create
          <span class="font-weight-bold">a new contact</span> or would modify an
          existent contact, use the options "Create Contact" or "Modify Contact"
        </p>

        <form>
          <div class="form-group row">
            <label for="" class="col-sm-3 col-form-label"
              >Registrant (reg-c)</label
            >
            <div class="col-sm-9">
              <b-skeleton type="input" v-if="loading"></b-skeleton>
              <b-form-select
                v-else
                v-model="selected.reg_c"
                :options="get_contact_for_select"
              ></b-form-select>
            </div>
          </div>

          <div class="form-group row">
            <label for="" class="col-sm-3 col-form-label"
              >Administrator (admin-c)</label
            >
            <div class="col-sm-9">
              <b-skeleton type="input" v-if="loading"></b-skeleton>
              <b-form-select
                v-else
                v-model="selected.admin_c"
                :options="get_contact_for_select"
              ></b-form-select>
            </div>
          </div>

          <div class="form-group row">
            <label for="" class="col-sm-3 col-form-label"
              >Technical (tech-c)</label
            >
            <div class="col-sm-9">
              <b-skeleton type="input" v-if="loading"></b-skeleton>
              <b-form-select
                v-else
                v-model="selected.tech_c"
                :options="get_contact_for_select"
              ></b-form-select>
            </div>
          </div>
          <div class="form-group row">
            <label for="" class="col-sm-3 col-form-label"
              >Billing (bill-c)</label
            >
            <div class="col-sm-9">
              <b-skeleton type="input" v-if="loading"></b-skeleton>
              <b-form-select
                v-else
                v-model="selected.bill_c"
                :options="get_contact_for_select"
              ></b-form-select>
            </div>
          </div>

          <div class="row">
            <div class="col-sm-3 offset-sm-3">
              <b-skeleton type="button" v-if="loading"></b-skeleton>
              <button class="btn btn-success" @click="update()" v-else>
                Save Changes
              </button>
            </div>
          </div>
        </form>
      </template>
    </RWCard>
  </div>
</template>

<script type="text/javascript">
import { loading, ApiURL, notif } from "@/common/mixins/general.js";
import RWCard from "@/views/partials/content/Portlet.vue";
import { contactMixin } from "@/common/mixins/product/domain/contact-mixin.js";

export default {
  mixins: [contactMixin, loading, ApiURL, notif],
  components: { RWCard },
  props: {
    DomainID: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      loading: true,
      selected: {
        reg_c: 0,
        admin_c: 0,
        tech_c: 0,
        bill_c: 0
      }
    };
  },
  methods: {
    /**
     * --------------------------------------------------
     * get list contact active / current
     * --------------------------------------------------
     */
    get_contact_current: function() {
      this.xhr_domain("contactchange", this.DomainID)
        .then(({ data }) => {
          let contact = data.data.contact;
          this.selected = {
            reg_c: contact["reg-c"],
            admin_c: contact["admin-c"],
            tech_c: contact["tech-c"],
            bill_c: contact["bill-c"]
          };
        })
        .finally(() => (this.loading = false));
    },

    /**
     * --------------------------------------------------
     * proses update ganti contact
     * --------------------------------------------------
     */
    update: function() {
      this.mx_loading();
      this.loading = true;
      let put = {
        "reg-c": this.selected.reg_c,
        "admin-c": this.selected.admin_c,
        "tech-c": this.selected.tech_c,
        "bill-c": this.selected.bill_c
      };

      this.xhr_domain_put("contactchange", put, this.DomainID)
        .then(({ data }) => {
          this.mx_swal(data.message, "", "success");
        })
        .catch(({ response }) => {
          this.mx_swal(response.data.message);
        })
        .finally(() => {
          this.get_contact_current();
          this.loading = false;
          this.mx_loading(false);
        });
    }
  },
  mounted() {
    let self = this;
    setTimeout(() => {
      self.get_contact_current();
    }, 2000);
  }
};
</script>
