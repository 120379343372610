<template>
  <div>
    <RWCard title="Managed DNS">
      <template #toolbar>
        <b-skeleton type="button" v-if="loading"></b-skeleton>
        <div v-else>
          <button
            v-if="terdaftar"
            class="btn btn-info btn-sm"
            @click="change_component('DnsAdd')"
          >
            <i class="fa fa-plus-circle"></i> Add New Record
          </button>
        </div>
      </template>
      <template v-slot:body>
        <div v-if="loading">
          <b-skeleton></b-skeleton>
          <b-skeleton></b-skeleton>
          <b-skeleton type="button" class="mx-auto"></b-skeleton>
        </div>
        <div v-else>
          <div v-if="!terdaftar" class="row justify-content-center">
            <div>
              <div class="col-sm-12">
                <div class="alert alert-info">
                  Domain Anda belum terdaftar di sistem DNS kami, untuk memulai
                  klik tombol "START"
                </div>
                <div class="text-center">
                  <button class="btn btn-primary" @click="start()">
                    START
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div v-else>
            <component
              :is="manageDnsComponent"
              :DomainID="DomainID"
              :DomainName="DomainName"
            ></component>
          </div>
        </div>
      </template>
    </RWCard>
  </div>
</template>

<script>
import { ApiURL } from "@/common/mixins/general.js";
import RWCard from "@/views/partials/content/Portlet.vue";
import DnsAdd from "@/views/pages/product/domain/management/dns/dns-add.vue";
import DnsList from "@/views/pages/product/domain/management/dns/dns-list.vue";
import DnsEdit from "@/views/pages/product/domain/management/dns/dns-edit.vue";

import { dnsMixin } from "@/common/mixins/product/domain/dns/dns-mixin.js";
import { mapGetters } from "vuex";

export default {
  mixins: [dnsMixin, ApiURL],
  props: {
    DomainID: {
      type: String,
      required: true
    },
    DomainName: {
      type: String,
      required: true
    }
  },
  components: {
    RWCard,
    DnsList,
    DnsAdd,
    DnsEdit
  },
  data() {
    return {
      loading: true,
      terdaftar: false
    };
  },
  methods: {
    /**
     * -------------------------------------------
     * mendaftarkan DNS ke sistem
     * -------------------------------------------
     */
    start() {
      this.xhr_domain("dnsinit", this.DomainID)
        .then(() => {
          this.terdaftar = true;
          this.change_component("DnsList");
        })
        .catch(({ response }) => {
          if (response.status === 400) {
            this.terdaftar = false;
          }
        });
    },

    /**
     * -------------------------------------------
     * cek apakah sudah
     * -------------------------------------------
     */
    get_dns_info() {
      this.xhr_domain("dnsmanagement", this.DomainID)
        .then(() => {
          this.terdaftar = true;
          this.change_component("DnsList");
        })
        .catch(({ response }) => {
          if (response.status === 400) {
            this.terdaftar = false;
          }
        })
        .finally(() => {
          this.loading = false;
        });
    }
  },
  computed: {
    /**
     * -------------------------------------------
     * for dynamic component, cause button add /
     * tambah ada id other component
     * -------------------------------------------
     */
    ...mapGetters(["manageDnsComponent"])
  },

  mounted() {
    this.get_dns_info();
  }
};
</script>

<style scoped>
.table th,
.table td {
  vertical-align: middle;
}
</style>
