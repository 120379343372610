<template>
  <div>
    <RWCard title="Domain Forwarding">
      <template v-slot:body>
        <div v-if="loading">
          <b-skeleton></b-skeleton>
          <v-skeleton-loader type="paragraph@2"></v-skeleton-loader>
        </div>
        <div v-else>
          <!-- belum aktif -->
          <div v-if="!is_active">
            <div class="alert alert-warning justify-content-center">
              Click on the button below to activate the Domain Forwarding
              Service for abc.com
            </div>
            <div class="row justify-content-center">
              <div class="col-sm-4">
                <button @click="configure()" class="btn btn-info btn-block">
                  Activate Domain Forwarding
                </button>
              </div>
            </div>
          </div>

          <!-- sudah aktif -->
          <div v-else>
            <form>
              <div class="form-group row">
                <label class="col-sm-2 col-form-label">Destination</label>
                <div class="col-sm-10">
                  <p class="form-control-plaintext">
                    URL where you wish to forward requests for and www. <br />
                    <b class="font-weight-bold">Note :</b> Insert the URL with
                    <code>http://</code> or <code>https://</code>
                  </p>
                  <input
                    type="text"
                    class="form-control"
                    v-model="form.target"
                  />
                </div>
              </div>
              <hr />
              <div class="form-group row">
                <label for="" class="col-sm-2 col-form-label"
                  >URL Masking</label
                >
                <div class="col-sm-10">
                  <p class="form-control-plaintext">
                    Enabling URL masking will ensure that your visitors see the
                    source URL and not the destination URL
                  </p>
                  <div class="form-check form-check-inline">
                    <input
                      class="form-check-input"
                      type="radio"
                      value="iframe"
                      v-model="form.type"
                    />
                    <label class="form-check-label">Yes</label>
                  </div>
                  <div class="form-check form-check-inline">
                    <input
                      class="form-check-input"
                      type="radio"
                      value="redirect"
                      v-model="form.type"
                    />
                    <label class="form-check-label">No</label>
                  </div>
                </div>
              </div>
              <hr />
              <div class="form-group row">
                <label for="" class="col-sm-2 col-form-label"
                  >Header Tags</label
                >
                <div class="col-sm-10">
                  <p class="form-control-plaintext">
                    Enabling URL Masking will serve a "Frames" page to the
                    browser. You can add TITLE and META tags for your "Frames"
                    page here. <br />
                    <b class="font-weight-bold">Examples :</b> <br />
                    <code>{{ code.header_tags.title }}</code> <br />
                    <code>{{ code.header_tags.keyword }}</code> <br />
                    <code>{{ code.header_tags.desc }}</code> <br />
                    <b class="font-weight-bold">Note :</b> Do not add the tags
                    <code>{{ code.header_tags.head }}</code
                    >. This will be added automatically.
                  </p>
                  <textarea
                    rows="4"
                    class="form-control"
                    v-model="form.header"
                  ></textarea>
                </div>
              </div>
              <hr />
              <div class="form-group row">
                <label for="" class="col-sm-2 col-form-label"
                  >No Frames Content</label
                >
                <div class="col-sm-10">
                  <p class="form-control-plaintext">
                    Enabling URL masking will serve a "Frames" page to the
                    browser. You may want to set an alternate "noframes" page
                    content for search engines. Enter your HTML within in the
                    "No Frames Content" box to set alternate page content.
                    <br />
                    <b class="font-weight-bold">Note :</b> Do not add the tags
                    <code>{{ code.no_frame }}</code
                    >. This will be added automatically.
                  </p>
                  <textarea
                    rows="4"
                    class="form-control"
                    v-model="form.noframe"
                  ></textarea>
                </div>
              </div>

              <hr />
              <div class="form-group row">
                <label for="" class="col-sm-2 col-form-label"
                  >Subdomain Forwarding</label
                >
                <div class="col-sm-10">
                  <p class="form-control-plaintext">
                    Enabling Sub Domain Forwarding will forward a request made
                    to
                    <code>http://subdomain</code>. to
                    <code>http://subdomain/</code>
                  </p>
                  <div class="form-check form-check-inline">
                    <input
                      class="form-check-input"
                      type="radio"
                      v-model="form.subdomain"
                      value="1"
                    />
                    <label class="form-check-label">Yes</label>
                  </div>
                  <div class="form-check form-check-inline">
                    <input
                      class="form-check-input"
                      type="radio"
                      v-model="form.subdomain"
                      value="0"
                    />
                    <label class="form-check-label">No</label>
                  </div>
                </div>
              </div>

              <hr />
              <div class="form-group row">
                <label for="" class="col-sm-2 col-form-label"
                  >Path Forwarding</label
                >
                <div class="col-sm-10">
                  <p class="form-control-plaintext">
                    Enabling Path Forwarding will forward requests made to
                    <code>http://some/path</code> to
                    <code>http://destination_url/some/path</code>
                  </p>
                  <div class="form-check form-check-inline">
                    <input
                      class="form-check-input"
                      type="radio"
                      value="1"
                      v-model="form.path"
                    />
                    <label class="form-check-label">Yes</label>
                  </div>
                  <div class="form-check form-check-inline">
                    <input
                      class="form-check-input"
                      type="radio"
                      value="0"
                      v-model="form.path"
                    />
                    <label class="form-check-label">No</label>
                  </div>
                </div>
              </div>
              <hr />
              <div class="row">
                <div class="col-sm-10 offset-sm-2">
                  <button
                    class="btn btn-success"
                    type="button"
                    @click="update()"
                  >
                    Save Change
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </template>
      <template v-slot:foot>
        <a @click="$router.go(-1)" href="#"
          ><i class="fa fa-angle-double-left"></i> Back</a
        >
      </template>
    </RWCard>
  </div>
</template>

<script>
import RWCard from "@/views/partials/content/Portlet.vue";
import {
  loading,
  ApiURL,
  httpCode,
  breadcrumb,
  notif
} from "@/common/mixins/general.js";
import { productDomainMixin } from "@/common/mixins/product/product-mixin.js";

export default {
  name: "DomainForwarding",
  mixins: [loading, ApiURL, productDomainMixin, httpCode, breadcrumb, notif],
  components: { RWCard },
  props: {
    DomainID: {
      required: true,
      type: String
    }
  },
  data() {
    return {
      is_active: false,
      loading: true,
      endpoint: "forwarding",
      code: {
        header_tags: {
          desc: '<meta name="description" CONTENT="website description">',
          head: "<head></head>",
          title: "<title>My Home page</title>",
          keyword: '<meta name="keywords" CONTENT="keyword1, keyword2, etc.">'
        },
        no_frame: "<noframes></noframes>"
      },
      form: {
        target: "",
        type: "redirect",
        header: "",
        noframe: "",
        subdomain: 0,
        path: 0
      }
    };
  },
  methods: {
    /**
     * -----------------------------------------------
     * info check domain forwarding status
     * -----------------------------------------------
     */
    get_info() {
      this.xhr_domain(this.endpoint, this.DomainID)
        .then(({ data }) => {
          this.form = data.data.forwarding;
          this.is_active = true;
        })
        .catch(({ response }) => {
          console.warn("[rumahweb]", response.data.message);
        })
        .finally(() => {
          this.loading = false;
          this.mx_loading(false);
        });
    },

    /**
     * -----------------------------------------------
     * start / init / configure domain forwarding
     * -----------------------------------------------
     */
    configure() {
      this.mx_loading();
      this.xhr_domain_post(this.endpoint, {}, this.DomainID)
        .then(({ data }) => {
          this.is_active = true;
          this.mx_swal(data.message, "", "success");
        })
        .catch(({ response }) => {
          this.mx_swal(response.data.message);
        })
        .finally(() => this.mx_loading(false));
    },

    /**
     * -----------------------------------------------
     * perbarui data domain forwarding
     * -----------------------------------------------
     */
    update: function() {
      this.mx_loading();
      this.xhr_domain_put(this.endpoint, this.form, this.DomainID)
        .then(({ data }) => {
          this.mx_swal(data.message, "", "success");
        })
        .catch(({ response }) => {
          this.mx_swal(response.data.message);
        })
        .finally(() => this.get_info());
    }
  },
  mounted() {
    this.get_info();
  }
};
</script>
