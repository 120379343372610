<template>
  <div>
    <div v-if="loading">please wait...</div>
    <component
      :is="menu"
      :DomainID="id"
      :DomainName="xhr.status.domain"
      v-else
    ></component>
  </div>
</template>

<script>
/**
 * -----------------------------------------
 * main component
 * -----------------------------------------
 */
import dns from "@/views/pages/product/domain/management/dns/dns-base.vue";
import dnssec from "@/views/pages/product/domain/management/dns-sec.vue";
import contact from "@/views/pages/product/domain/management/contact.vue";
import privatens from "@/views/pages/product/domain/management/private-ns.vue";
import forwarding from "@/views/pages/product/domain/management/domain-forwarding.vue";
import sosmedredirect from "@/views/pages/product/domain/management/sosmed-redirect.vue";
import { productDomainMixin } from "@/common/mixins/product/product-mixin.js";
import { breadcrumb, httpCode, loading } from "@/common/mixins/general.js";

export default {
  name: "BaseProductDomainManagement",
  mixins: [productDomainMixin, breadcrumb, httpCode, loading],
  props: {
    menu: {
      type: String,
      required: true
    },
    id: {
      type: String,
      required: true
    }
  },
  components: { dns, contact, dnssec, forwarding, privatens, sosmedredirect },
  data() {
    return {
      loading: true,
      xhr: {
        status: null
      }
    };
  },
  methods: {
    /**
     * -----------------------------------------
     * get status domain
     * -----------------------------------------
     */
    get_status(domain_id) {
      this.mx_loading();
      this.mx_status_domain(domain_id)
        .then(({ data }) => {
          this.xhr.status = data.data.status;

          /**
           * -----------------------------------------
           * set breadcrumb menu
           * -----------------------------------------
           */
          this.mx_DomainBreadcrumb([
            {
              title: data.data.status.domain,
              route: "product/domain/" + this.id
            },
            { title: this.menu }
          ]);
        })
        .catch(({ response }) => {
          this.mx_handleHttpCode(response.data.code, "/product/domain");
        })
        .finally(() => {
          this.mx_loading(false);
          this.loading = false;
        });
    }
  },
  mounted() {
    this.mx_loading();
    this.get_status(this.id);
  }
};
</script>
