<template>
  <div>
    <RWCard title="DNSSEC">
      <template #body>
        <div v-if="loading">
          <b-skeleton-table
            :rows="3"
            :columns="4"
            :table-props="{ bordered: true, striped: true }"
          ></b-skeleton-table>
        </div>
        <div v-else>
          <div v-if="dnssec.length === 0">
            <b-alert show variant="info">No Records Found</b-alert>
          </div>
          <b-table-simple responsive v-else hover striped>
            <b-thead>
              <b-tr>
                <b-th>Key Tag</b-th>
                <b-th>Algorithm</b-th>
                <b-th>Digest Type</b-th>
                <b-th>Digest</b-th>
              </b-tr>
            </b-thead>
            <b-tbody>
              <tr v-for="(dns, key) in dnssec" :key="key">
                <td>{{ dns.keytag }}</td>
                <td>{{ dns.algorithm }}</td>
                <td>{{ dns.digesttype }}</td>
                <td>{{ dns.digest }}</td>
                <td>
                  <button class="btn btn-danger btn-sm" @click="hapus(dns.id)">
                    Delete
                  </button>
                </td>
              </tr>
            </b-tbody>
          </b-table-simple>
        </div>
      </template>
    </RWCard>

    <RWCard :title="title">
      <template v-slot:body>
        <div class="alert alert-warning">
          <b class="mr-2"
            ><i class="fa fa-exclamation-triangle"></i> Warning!</b
          >
          Make sure you understand using DNSSEC function, failure when creating
          record will make your domain cannot be accessed.
        </div>

        <!-- form tambah -->
        <div class="row justify-content-center">
          <div class="col-sm-6">
            <form @submit.stop.prevent="create">
              <div class="form-group row my-3">
                <label for="" class="col-sm-3 col-form-label">Key Tag</label>
                <div class="col-sm-9">
                  <b-form-input
                    type="number"
                    v-model.trim="$v.form.keytag.$model"
                    :state="validateState('keytag')"
                    placeholder="Should be an integer between 0 and 65535"
                  ></b-form-input>
                </div>
              </div>
              <div class="form-group row my-3">
                <label for="" class="col-sm-3 col-form-label">Algorithm</label>
                <div class="col-sm-9">
                  <b-form-select
                    v-model="form.algorithm"
                    :options="algorithm.data"
                    class="form-control"
                  ></b-form-select>
                </div>
              </div>
              <div class="form-group row my-3">
                <label for="" class="col-sm-3 col-form-label"
                  >Digest Type</label
                >
                <div class="col-sm-9">
                  <b-form-select
                    v-model="form.digesttype"
                    :options="digest.data"
                  ></b-form-select>
                </div>
              </div>
              <div class="form-group row my-3">
                <label for="" class="col-sm-3 col-form-label">Digest</label>
                <div class="col-sm-9">
                  <b-form-input
                    type="text"
                    v-model.trim="$v.form.digest.$model"
                    :state="validateState('digest')"
                    placeholder="Should be 40 or 64 characters long"
                  ></b-form-input>
                </div>
              </div>

              <div class="row">
                <div class="col-sm-10 offset-sm-3">
                  <button class="btn btn-success">Add Record</button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </template>
      <template v-slot:foot>
        <button @click="$router.go(-1)" class="btn btn-secondary">
          <i class="fa fa-angle-double-left"></i> Back
        </button>
      </template>
    </RWCard>
  </div>
</template>

<script>
import { validationMixin } from "vuelidate";
import { minLength, required } from "vuelidate/lib/validators";
import { loading, ApiURL, notif } from "@/common/mixins/general.js";
import RWCard from "@/views/partials/content/Portlet.vue";
export default {
  components: {
    RWCard
  },
  props: {
    DomainID: {
      type: String,
      required: true
    },
    DomainName: {
      type: String,
      required: true
    }
  },
  mixins: [loading, ApiURL, notif, validationMixin],
  data() {
    return {
      loading: true,
      dnssec: [],
      form: {
        keytag: "",
        algorithm: 1,
        digesttype: 1,
        digest: ""
      },
      algorithm: {
        data: [
          { value: "1", text: "RSA/MD5 (1)" },
          { value: "2", text: "Diffie-Hellman (2)" },
          { value: "3", text: "DSA-SHA1 (3)" },
          { value: "4", text: "Elliptic Curve (ECC) (4)" },
          { value: "5", text: "RSA-SHA1 (5)" },
          { value: "6", text: "DSA-SHA1-NSEC3 (6)" },
          { value: "7", text: "RSA-SHA1-NSEC3 (7)" },
          { value: "8", text: "RSA-SHA256 (8)" },
          { value: "10", text: "RSA-SHA512 (10)" },
          { value: "13", text: "ECDSA Curve P-256 with SHA-256 (13)" },
          { value: "252", text: "Indirect (252)" },
          { value: "253", text: "Private [PRIVATEDNS] (253)" },
          { value: "254", text: "Private [PRIVATEOID] (254)" }
        ]
      },
      digest: {
        data: [
          { value: "1", text: "1" },
          { value: "2", text: "2" },
          { value: "3", text: "3" }
        ]
      }
    };
  },
  validations: {
    form: {
      keytag: { required },
      digest: { required, minLength: minLength(40) }
    }
  },
  computed: {
    title: function() {
      return `Add record for ${this.DomainName}`;
    }
  },
  methods: {
    validateState(name) {
      const { $dirty, $error } = this.$v.form[name];
      return $dirty ? !$error : null;
    },

    /**
     * ----------------------------------------------------
     * get list all DNSSEC domain ini
     * ----------------------------------------------------
     */
    get_dnssec: function() {
      this.xhr_domain("dnssec", this.DomainID)
        .then(({ data }) => {
          this.dnssec = data.data.dnssec;
        })
        .finally(() => {
          this.loading = false;
        });
    },

    /**
     * ----------------------------------------------------
     * create DNSSEC
     * ----------------------------------------------------
     */
    create: function() {
      this.$v.form.$touch();
      if (this.$v.form.$anyError) {
        return;
      }

      this.mx_loading();
      this.loading = true;
      this.xhr_domain_post("dnssec", this.form, this.DomainID)
        .then(({ data }) => {
          this.mx_swal(data.message, "", "success");
          this.get_dnssec();

          // reset form
          this.$v.form.$reset();
          this.form.keytag = "";
          this.form.digest = "";
        })
        .catch(({ response }) => {
          this.mx_swal(response.data.message);
        })
        .finally(() => {
          this.mx_loading(false);
          this.loading = false;
        });
    },

    /**
     * ----------------------------------------------------
     * create DNSSEC
     * ----------------------------------------------------
     */
    hapus: function(id) {
      this.mx_swal_confirm("Are you sure ?").then(() => {
        this.mx_loading();
        this.loading = true;
        this.xhr_domain_delete("dnssec", { id: id }, this.DomainID)
          .then(({ data }) => {
            this.mx_swal(data.message, "", "success");
            this.get_dnssec();
          })
          .catch(({ response }) => {
            this.mx_swal(response.data.message);
          })
          .finally(() => {
            this.mx_loading(false);
            this.loading = false;
          });
      });
    }
  },
  mounted() {
    this.get_dnssec();
  }
};
</script>
