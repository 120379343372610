<template>
  <div>
    <RWCard title="Delete Contact">
      <template #body>
        <p>
          Select the Contact you wish to delete and click the button
          <span class="font-weight-bold">"Delete Selected Contact"</span>
          button.
        </p>
        <p>
          <span class="font-weight-bold">Note :</span> A Domain Contact cannot
          be deleted if it is asociated with one of your domains.
        </p>
        <form @submit.stop.prevent="contact_hapus()">
          <div class="form-group row">
            <label for="" class="col-sm-2 col-form-label">Contact</label>
            <div class="col-sm-9">
              <b-form-select
                v-model="selected"
                :options="get_contact_for_select"
              ></b-form-select>
            </div>
          </div>
          <div class="row">
            <div class="col-sm-5 offset-2">
              <button class="btn btn-danger">Delete Selected Contact</button>
            </div>
          </div>
        </form>
      </template>
    </RWCard>
  </div>
</template>

<script>
import RWCard from "@/views/partials/content/Portlet.vue";
import { contactMixin } from "@/common/mixins/product/domain/contact-mixin.js";
import { loading, ApiURL, notif } from "@/common/mixins/general.js";

export default {
  mixins: [contactMixin, loading, ApiURL, notif],
  components: { RWCard },
  props: {
    DomainID: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      selected: ""
    };
  },
  methods: {
    contact_hapus: function() {
      if (this.selected === "") {
        this.mx_swal("You haven't selected a contact");
      } else {
        this.mx_swal_confirm(
          "You really want permanently delete the selected contact?"
        ).then(() => {
          this.mx_loading();
          let data = { contactid: this.selected };
          this.xhr_domain_delete("contact", data, this.DomainID)
            .then(({ data }) => {
              this.mx_swal(data.message, "", "success");
              this.$emit("changeAction", "Change Contact");
            })
            .catch(({ response }) => {
              this.mx_swal(response.data.message);
            })
            .finally(() => {
              this.mx_loading(false);
            });
        });
      }
    }
  }
};
</script>
