<template>
  <div class="row justify-content-center">
    <div class="col-sm-6">
      <div class="alert alert-info">
        Modify {{ dns.type }} Record: {{ dns.record }}
      </div>

      <form @submit="put_submit">
        <div class="form-group row my-3">
          <label for="" class="col-sm-3 col-form-label">Domain</label>
          <div class="col-sm-9">
            <div class="input-group">
              <input type="text" class="form-control" v-model="form.record" />
              <div class="input-group-prepend">
                <div class="input-group-text">.{{ DomainName }}</div>
              </div>
            </div>
          </div>
        </div>

        <div class="form-group row my-3">
          <label class="col-sm-3 col-form-label">TTL</label>
          <div class="col-sm-9">
            <input type="number" class="form-control" v-model="form.ttl" />
          </div>
        </div>

        <div class="form-group row my-3">
          <label for="staticType" class="col-sm-3 col-form-label"
            >Record Type</label
          >
          <div class="col-sm-9">
            <input
              type="text"
              readonly
              class="form-control-plaintext"
              id="staticType"
              v-model="form.type"
            />
          </div>
        </div>

        <div class="form-group row my-3" v-if="dns.type === 'A'">
          <label class="col-sm-3 col-form-label">IP or Hostname</label>
          <div class="col-sm-9">
            <input type="text" class="form-control" v-model="form.hostname" />
          </div>
        </div>

        <div class="form-group row my-3" v-if="dns.type === 'AAAA'">
          <label class="col-sm-3 col-form-label">IPv6 Address</label>
          <div class="col-sm-9">
            <input type="text" class="form-control" v-model="form.hostname" />
          </div>
        </div>

        <div
          class="form-group row my-3"
          v-if="
            dns.type === 'CNAME' ||
              dns.type === 'MX' ||
              dns.type === 'NS' ||
              dns.type === 'SRV'
          "
        >
          <label class="col-sm-3 col-form-label">Hostname</label>
          <div class="col-sm-9">
            <input type="text" class="form-control" v-model="form.hostname" />
          </div>
        </div>

        <div
          class="form-group row my-3"
          v-if="dns.type === 'SRV' || dns.type === 'MX'"
        >
          <label class="col-sm-3 col-form-label">Priority</label>
          <div class="col-sm-9">
            <input type="text" class="form-control" v-model="form.priority" />
          </div>
        </div>

        <div class="form-group row my-3" v-if="dns.type === 'SRV'">
          <label class="col-sm-3 col-form-label">Weight</label>
          <div class="col-sm-9">
            <input type="text" class="form-control" v-model="form.weight" />
          </div>
        </div>

        <div class="form-group row my-3" v-if="dns.type === 'SRV'">
          <label class="col-sm-3 col-form-label">Port</label>
          <div class="col-sm-9">
            <input type="text" class="form-control" v-model="form.port" />
          </div>
        </div>

        <div class="form-group row my-3" v-if="dns.type === 'TXT'">
          <label class="col-sm-3 col-form-label">Text Information</label>
          <div class="col-sm-9">
            <textarea class="form-control" v-model="form.hostname"></textarea>
          </div>
        </div>

        <div class="row">
          <div class="col-sm-10 offset-3">
            <button class="btn btn-success btn-sm">Apply Modification</button>
            &nbsp;
            <button
              class="btn btn-secondary btn-sm"
              @click="change_component('DnsList')"
            >
              <i class="fa fa-angle-double-left"></i> Back
            </button>
          </div>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { dnsMixin } from "@/common/mixins/product/domain/dns/dns-mixin.js";
import { ApiURL, notif, loading } from "@/common/mixins/general.js";
export default {
  mixins: [dnsMixin, ApiURL, notif, loading],
  props: {
    DomainName: {
      type: String,
      required: true
    },
    DomainID: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      form: {
        record: "",
        ttl: "",
        hostname: "",
        priority: "",
        wight: "",
        port: "",
        type: ""
      }
    };
  },
  methods: {
    put_submit(e) {
      e.preventDefault();
      const data = {
        record: this.form.record === "" ? 0 : this.form.record,
        type: this.form.type,
        ttl: this.form.ttl,
        destination: this.form.hostname,
        priority: this.form.priority,
        weight: this.form.weight,
        port: this.form.port,
        dnsid: this.dns.dnsid
      };

      this.mx_loading();
      this.xhr_domain_put("dnsmanagement", data, this.DomainID)
        .then(({ data }) => {
          this.mx_swal(data.message, "", "success");
          this.change_component("DnsList");
        })
        .catch(({ response }) => {
          this.mx_swal(response.data.message);
        })
        .finally(() => {
          this.mx_loading(false);
        });
    }
  },
  computed: {
    ...mapGetters(["getDNSedit"]),
    dns() {
      return this.getDNSedit;
    }
  },
  mounted() {
    /**
     * -------------------------------------------
     * remove domain from record
     * -------------------------------------------
     */
    let destination = this.dns.record;
    let replace = "";
    if (this.dns.record === this.dns.domain + ".") {
      replace = "";
    } else {
      replace = destination.replace("." + this.dns.domain + ".", "");
    }

    this.form.record = replace;
    this.form.hostname = this.dns.destination; // destination
    this.form.priority = this.dns.priority;
    this.form.weight = this.dns.destination;
    this.form.port = this.dns.port;
    this.form.ttl = this.dns.ttl;
    this.form.type = this.dns.type;
  }
};
</script>
