<template>
  <div>
    <div class="row">
      <b-col sm="4">
        <div class="alert alert-info">
          From here you can create and manage custom nameservers for your domain
          (eg. NS1.yourdomain.com, NS2.yourdomain.com, etc.)
        </div>
      </b-col>

      <div class="col-sm-8">
        <!-- list ns -->
        <RWCard title="Private Name Server">
          <template #toolbar>
            <b-skeleton type="button" v-if="loading"></b-skeleton>
            <b-button
              v-else
              variant="primary"
              size="sm"
              @click="
                show_modal = true;
                reset(1);
              "
              ><i class="fa fa-plus-circle"></i> Add New</b-button
            >
          </template>
          <template #body>
            <b-skeleton-table
              v-if="loading"
              :rows="3"
              :columns="3"
              :table-props="{ bordered: true, striped: true }"
            ></b-skeleton-table>
            <div v-else>
              <div v-if="!success">
                <b-alert show variant="danger">{{ items.message }}</b-alert>
              </div>
              <div v-else>
                <div v-if="items.length === 0">
                  <div class="alert alert-warning">
                    Belum ada child yang terdaftar
                  </div>
                </div>
                <b-table
                  striped
                  hover
                  :items="items"
                  :fields="fields"
                  :responsive="true"
                  v-else
                >
                  <template #cell(action)="data">
                    <b-button
                      variant="success"
                      size="sm"
                      @click="perbarui(data.item)"
                      ><span class="fa fa-edit"></span>
                      <span class="d-none d-sm-inline">edit</span></b-button
                    >
                    &nbsp;
                    <b-button
                      variant="danger"
                      size="sm"
                      @click="hapus(data.item.ns)"
                      ><span class="fa fa-trash"></span>
                      <span class="d-none d-sm-inline">delete</span></b-button
                    >
                  </template>
                </b-table>
              </div>
            </div>
          </template>
        </RWCard>
      </div>
    </div>

    <!-- modal  -->
    <b-modal
      id="modalForm"
      :title="form.title"
      hide-footer
      v-model="show_modal"
    >
      <form @submit.stop.prevent="submit" @reset="reset">
        <div class="form-group row mb-3">
          <label for="" class="col-sm-3 col-form-label">Name Server</label>
          <div class="col-sm-8">
            <b-input-group :append="domain" class="mb-2 mr-sm-2 mb-sm-0">
              <b-form-input
                class="form-control"
                v-model.trim="$v.form.ns.$model"
                :state="validasi_state('ns')"
              ></b-form-input>
            </b-input-group>
          </div>
        </div>
        <div class="form-group row mb-3">
          <label for="" class="col-sm-3 col-form-label">IP Address</label>
          <div class="col-sm-8">
            <b-form-input
              class="form-control"
              v-model.trim="$v.form.ip.$model"
              :state="validasi_state('ip')"
              autocomplete="false"
            ></b-form-input>

            <b-form-invalid-feedback v-if="$v.form.ip.$error">
              IP Address is required and must be valid IPv4.
            </b-form-invalid-feedback>
          </div>
        </div>
        <div class="row">
          <div class="col-sm-8 offset-sm-3">
            <b-button variant="warning" type="button" @click="reset()"
              >Reset</b-button
            >
            &nbsp;
            <b-button variant="primary" type="submit">Save Changes</b-button>
          </div>
        </div>
      </form>
    </b-modal>
  </div>
</template>

<script>
import RWCard from "@/views/partials/content/Portlet.vue";
import { ApiURL, httpCode, loading, notif } from "@/common/mixins/general.js";

// validasi
import { validationMixin } from "vuelidate";
import { ipAddress, required } from "vuelidate/lib/validators";

export default {
  name: "productDomainPrivateNameServer",
  mixins: [ApiURL, httpCode, loading, notif, validationMixin],
  components: { RWCard },
  props: {
    DomainID: {
      type: String,
      required: true
    },
    DomainName: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      success: false,
      loading: true,
      form: {
        title: "Register",
        id: null,
        ns: "",
        ip: ""
      },
      fields: [
        {
          key: "ns",
          label: "Name Server",
          sortable: true
        },
        {
          key: "ip",
          label: "IP Address",
          sortable: true
        },
        {
          key: "action",
          label: "#",
          sortable: true
        }
      ],
      items: [],
      show_modal: false
    };
  },

  methods: {
    validasi_state(name) {
      const { $dirty, $error } = this.$v.form[name];
      return $dirty ? !$error : null;
    },
    /**
     * ----------------------------------------------
     * get all data private ns
     * ----------------------------------------------
     */
    get_privatens() {
      this.xhr_domain("privatens", this.DomainID)
        .then(({ data }) => {
          this.items = data.data.privatens;
          this.success = true;
        })
        .catch(({ response }) => {
          this.items = response.data;
        })
        .finally(() => (this.loading = false));
    },

    /**
     * -----------------------------------------
     * show form edit
     * -----------------------------------------
     */
    perbarui(item) {
      console.log(item);
      const removeDomainName = function(nameserver, domain) {
        let regex = new RegExp("(." + domain + ")$", "g");
        return nameserver.replace(regex, "");
      };

      this.form.title = "Modify";
      this.form.id = item.id;
      this.form.ip = item.ip;
      this.form.ns = removeDomainName(item.ns, this.DomainName);
      this.show_modal = true;
    },

    /**
     * ----------------------------------------------
     * submit form proses tambah / edit
     * ----------------------------------------------
     */
    submit: function() {
      this.$v.form.$touch();
      if (this.$v.form.$anyError) return;

      this.mx_loading();
      this.show_modal = false;
      let data = {
        nameserver: this.form.ns,
        ipaddress: this.form.ip
      };
      if (this.form.id === null) {
        /**
         * --------------------------------------------
         * proses tambah data
         * --------------------------------------------
         */
        this.xhr_domain_post("privatens", data, this.DomainID)
          .then(({ data }) => {
            this.loading = true;
            this.get_privatens();
            this.mx_swal(data.message, "", "success");
          })
          .catch(({ response }) => {
            this.mx_swal(response.data.message);
          })
          .finally(() => {
            this.mx_loading(false);
          });
      } else {
        /**
         * --------------------------------------------
         * proses edit data
         * --------------------------------------------
         */
        this.xhr_domain_put("privatens", data, this.DomainID)
          .then(() => {
            this.loading = true;
            this.get_privatens();
            this.mx_swal("success", "", "success");
          })
          .finally(() => {
            this.mx_loading(false);
          });
      }
    },

    /**
     * ---------------------------------------------
     * reset form
     * ---------------------------------------------
     */
    reset: function(reg = false) {
      if (reg) {
        console.log(reg);
        this.form.title = "Register";
        this.form.id = null;
      }

      this.form.ip = null;
      this.form.ns = null;
    },

    /**
     * ---------------------------------------------
     * hapus data private ns
     * ---------------------------------------------
     */
    hapus: function(nameserver = false) {
      this.mx_swal_confirm(
        "You won't be able to revert this!",
        "",
        "warning"
      ).then(() => {
        this.mx_loading();
        this.xhr_domain_delete(
          "privatens",
          { nameserver: nameserver },
          this.DomainID
        )
          .then(() => {
            this.loading = true;
            this.get_privatens();
            this.mx_swal("Your Nameserver has been deleted.", "", "success");
          })
          .catch(({ response }) => this.mx_swal(response.data.message))
          .finally(() => this.mx_loading(false));
      });
    }

    /**
     * ------------------------------------------
     * remove domain utama
     * ex. aaaa.b.com => b.com
     * aaa.aa.b.com.b.com => b.com
     * ------------------------------------------
     */
    // mx_remove_domain : function (str, search, replaceWith = "") {

    //   var str1 = "abc.sss.ssss.sss.lorem.com.lorem.com";
    //   return str.replace(/(.lorem.com)$/g, "");
    // }
  },
  validations: {
    form: {
      ns: { required },
      ip: { required, ipAddress }
    }
  },
  computed: {
    /**
     * ------------------------------------
     * add prefix dot(.) for append field form
     * ------------------------------------
     */
    domain: function() {
      return "." + this.DomainName;
    }
  },
  mounted() {
    this.get_privatens();
    this.$root.$on("bv::modal::hide", () => {
      // this.reset();
    });
  }
};
</script>
