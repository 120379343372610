<template>
  <div>
    <component :is="manageContactComponent" :DomainID="DomainID"></component>
  </div>
</template>

<script>
import RWCard from "@/views/partials/content/Portlet.vue";
import ContactInfo from "@/views/pages/product/domain/management/contact/contac-info.vue";
import ContactAction from "@/views/pages/product/domain/management/contact/actions/contact-base-action.vue";
import { mapGetters } from "vuex";

export default {
  name: "contact-base",
  props: {
    DomainID: {
      type: String,
      required: true
    }
  },
  components: {
    RWCard,
    ContactInfo,
    ContactAction
  },

  data() {
    return {
      view: ContactInfo
    };
  },
  computed: {
    ...mapGetters(["manageContactComponent"])
  }
};
</script>
