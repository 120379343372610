/**
 * ---------------------------------------------
 * function global untuk contact
 * ---------------------------------------------
 */
import { allContactWhois } from "@/store/rw/product.domain.module.js";
import { mapGetters } from "vuex";

export const contactMixin = {
  methods: {
    set_contact(allContacts) {
      this.$store.dispatch(allContactWhois, allContacts);
    }
  },
  computed: {
    get_contact() {
      return this.getContactWhois;
    },

    /**
     * ----------------------------------------
     * custom contact for view select option
     * supaya bisa digunakan di child action
     * ex. change, delete, modify
     * ----------------------------------------
     */
    get_contact_for_select() {
      let plainContact = this.getContactWhois;
      let selectOption = [];
      for (let contact in plainContact) {
        let index = plainContact[contact];
        let pushContact = {
          value: index["contactid"],
          text:
            "#" +
            index["contactid"] +
            " " +
            index["fname"] +
            " " +
            index["lname"] +
            " " +
            index["email"]
        };
        selectOption.push(pushContact);
      }
      return selectOption;
    },
    ...mapGetters(["getContactWhois"])
  }
};
