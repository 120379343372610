<template>
  <div class="row">
    <div class="col-sm-3">
      <RWCard title="Actions" :bodyFit="true">
        <template #body>
          <div
            class="list-group list-group-flush"
            v-for="(action, index) in actions"
            :key="index"
          >
            <a
              class="list-group-item list-group-item-action"
              :class="selected === action ? 'active' : ''"
              @click="select_action(action)"
            >
              {{ action }}
            </a>
          </div>
        </template>
      </RWCard>
      <button class="btn btn-primary btn-block my-5" @click="back_to_contact()">
        <i class="fa fa-angle-double-left"></i> Back
      </button>
    </div>
    <div class="col-sm-9">
      <component
        :is="view"
        :DomainID="DomainID"
        v-on:changeAction="childChangeView"
      ></component>
    </div>
  </div>
</template>

<script>
import { loading, ApiURL, notif } from "@/common/mixins/general.js";
import RWCard from "@/views/partials/content/Portlet.vue";
import { changeContactComponent } from "@/store/rw/product.domain.module.js";
import { contactMixin } from "@/common/mixins/product/domain/contact-mixin.js";

/**
 * -----------------------------
 * component setiap action
 * -----------------------------
 */
import changeContact from "@/views/pages/product/domain/management/contact/actions/contact-change.vue";
import createContact from "@/views/pages/product/domain/management/contact/actions/contact-create.vue";
import deleteContact from "@/views/pages/product/domain/management/contact/actions/contact-delete.vue";
import modifyContact from "@/views/pages/product/domain/management/contact/actions/contact-modify.vue";

export default {
  mixins: [contactMixin, loading, ApiURL, notif],
  props: {
    DomainID: {
      type: String,
      required: true
    }
  },
  components: {
    RWCard,
    changeContact,
    createContact,
    deleteContact,
    modifyContact
  },
  data() {
    return {
      actions: [
        "Change Contact",
        "Modify Contact",
        "Delete Contact",
        "Create Contact"
      ],
      selected: "Change Contact"
    };
  },
  methods: {
    /**
     * ----------------------------------------------------
     * pilih menu action nya
     * ----------------------------------------------------
     */
    select_action(name) {
      this.selected = name;

      // jika ada redirect dari child
      // ex. sukses hapus, edit, create
      if (name === "Change Contact") {
        this.get_all_contact();
      }
    },
    back_to_contact() {
      this.$store.dispatch(changeContactComponent, "ContactInfo");
    },

    /**
     * ----------------------------------------------------
     * get all contact whois
     * ----------------------------------------------------
     */
    get_all_contact() {
      this.xhr_domain("contact", this.DomainID).then(({ data }) => {
        this.set_contact(data.data.contact);
      });
    },

    /**
     * ----------------------------------------------------
     * trigger child change component
     * ex. setelah berhasil create contact redirect ke change contact
     * ----------------------------------------------------
     */
    childChangeView: function(name_action) {
      this.select_action(name_action);
    }
  },
  computed: {
    view() {
      let view;
      switch (this.selected) {
        case "Modify Contact":
          view = "modifyContact";
          break;

        case "Delete Contact":
          view = "deleteContact";
          break;

        case "Create Contact":
          view = "createContact";
          break;

        default:
          view = "changeContact";
          break;
      }
      return view;
    }
  },

  mounted() {
    this.get_all_contact();
  }
};
</script>

<style scoped>
.list-group-flush > .list-group-item {
  cursor: pointer;
  border-width: 0 0 1px;
}
</style>
