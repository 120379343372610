<template>
  <div>
    <div v-if="loading">
      <b-skeleton-table
        :rows="5"
        :columns="7"
        :table-props="{ bordered: true, striped: true }"
      ></b-skeleton-table>
    </div>
    <div v-else>
      <b-alert show v-if="xhr.dns.length === 0">Dns belum ada!</b-alert>
      <b-table-simple responsive striped hover class="my-3" v-else>
        <b-thead>
          <b-tr>
            <b-th>Domain</b-th>
            <b-th>TTL</b-th>
            <b-th>Class</b-th>
            <b-th>Type</b-th>
            <b-th>Priority</b-th>
            <b-th>Destination</b-th>
          </b-tr>
        </b-thead>
        <b-tbody>
          <b-tr v-for="(row, key) in xhr.dns" :key="key">
            <b-td class="align-middle">{{ row.domain }}</b-td>
            <b-td>{{ row.ttl }}</b-td>
            <b-td>{{ row.class }}</b-td>
            <b-td>{{ row.type }}</b-td>
            <b-td>{{ row.priority }}</b-td>
            <b-td>{{ row.destination }}</b-td>
            <b-td>
              <button @click="btn_edit(row)" class="btn btn-sm btn-success">
                <i class="fa fa-edit"></i
                ><span class="d-none d-sm-inline"> Edit</span>
              </button>
              &nbsp;
              <button
                @click="btn_delete(row.dnsid)"
                class="btn btn-sm btn-danger"
              >
                <i class="fa fa-trash"></i>
                <span class="d-none d-sm-inline">Delete</span>
              </button>
            </b-td>
          </b-tr>
        </b-tbody>
      </b-table-simple>
    </div>
  </div>
</template>

<script>
import ApiService from "@/common/api.service";
import { ApiURL, notif, loading } from "@/common/mixins/general.js";
import { dnsMixin } from "@/common/mixins/product/domain/dns/dns-mixin.js";

export default {
  mixins: [dnsMixin, ApiURL, notif, ApiService, loading],
  props: {
    DomainID: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      loading: true,
      xhr: {
        dns: {}
      }
    };
  },
  methods: {
    get_list() {
      this.xhr_domain("dnsmanagement", this.DomainID)
        .then(({ data }) => {
          this.xhr.dns = data.data.nameserver;
        })
        .finally(() => {
          this.loading = false;
        });
    },

    /**
     * -------------------------------------------------------
     * params dns id : proses delete dns
     * -------------------------------------------------------
     */
    btn_delete(dnsid) {
      this.mx_swal_confirm("Data can't be restore!", "Warning", "warning").then(
        () => {
          this.mx_loading();
          ApiService.setHeader();
          ApiService.delete(
            this.api.product.domain.dnsmanagement + "/" + this.DomainID,
            { dnsid: dnsid }
          )
            .then(() => {
              this.mx_swal("Deleted", "", "success");
            })
            .catch(({ response }) => {
              this.mx_swal(response.data.message, "");
            })
            .finally(() => {
              this.get_list();
              this.mx_loading(false);
            });
        }
      );
    },

    btn_edit(params) {
      this.change_component("DnsEdit");
      this.send_DNS_edit(params);
    }
  },

  mounted() {
    this.get_list();
  }
};
</script>
