<template>
  <div>
    <RWCard title="Modify Contact">
      <template #body v-if="!is_edit">
        <p>
          Select the contact you wish to modify from the dropdown below and
          click the
          <span class="font-weight-bold">"Modify Selected Contact"</span>
          button.
        </p>

        <form @submit.stop.prevent="on_submit">
          <div class="form-group row">
            <label for="" class="col-sm-2 col-form-label">Contact</label>
            <div class="col-sm-9">
              <b-form-select
                v-model="selected"
                :options="get_contact_for_select"
              ></b-form-select>
            </div>
          </div>
          <div class="row">
            <div class="col-sm-5 offset-2">
              <button class="btn btn-info">Modify Selected Contact</button>
            </div>
          </div>
        </form>
      </template>
      <template #body v-else>
        <form @submit.stop.prevent="on_submit_edit">
          <div class="form-group row mb-3">
            <label for="" class="col-sm-3 col-form-label">First Name</label>
            <div class="col-sm-9">
              <b-form-input
                type="text"
                v-model.trim="$v.form.fname.$model"
                :state="validateState('fname')"
              ></b-form-input>
            </div>
          </div>
          <div class="form-group row mb-3">
            <label for="" class="col-sm-3 col-form-label">Last Name</label>
            <div class="col-sm-9">
              <b-form-input
                type="text"
                v-model.trim="$v.form.lname.$model"
                :state="validateState('lname')"
              ></b-form-input>
            </div>
          </div>
          <div class="form-group row mb-3">
            <label for="" class="col-sm-3 col-form-label">Company Name</label>
            <div class="col-sm-9">
              <b-form-input
                type="text"
                v-model.trim="$v.form.company.$model"
                :state="validateState('company')"
              ></b-form-input>
            </div>
          </div>
          <div class="form-group row mb-3">
            <label for="" class="col-sm-3 col-form-label">Email Address</label>
            <div class="col-sm-9">
              <b-form-input
                type="text"
                v-model.trim="$v.form.email.$model"
                :state="validateState('email')"
              ></b-form-input>
            </div>
          </div>
          <div class="form-group row mb-3">
            <label for="" class="col-sm-3 col-form-label">Address 1</label>
            <div class="col-sm-9">
              <b-form-input
                type="text"
                v-model.trim="$v.form.address.$model"
                :state="validateState('address')"
              ></b-form-input>
            </div>
          </div>
          <div class="form-group row mb-3">
            <label for="" class="col-sm-3 col-form-label">Address 2</label>
            <div class="col-sm-9">
              <b-form-input
                type="text"
                v-model.trim="$v.form.address2.$model"
                :state="validateState('address2')"
              ></b-form-input>
            </div>
          </div>
          <div class="form-group row mb-3">
            <label for="" class="col-sm-3 col-form-label">Address 3</label>
            <div class="col-sm-9">
              <b-form-input
                type="text"
                v-model.trim="$v.form.address3.$model"
                :state="validateState('address3')"
              ></b-form-input>
            </div>
          </div>
          <div class="form-group row mb-3">
            <label for="" class="col-sm-3 col-form-label">Zip Code</label>
            <div class="col-sm-9">
              <b-form-input
                type="text"
                v-model.trim="$v.form.postal_code.$model"
                :state="validateState('postal_code')"
              ></b-form-input>
            </div>
          </div>
          <div class="form-group row mb-3">
            <label for="" class="col-sm-3 col-form-label">City</label>
            <div class="col-sm-9">
              <b-form-input
                type="text"
                v-model.trim="$v.form.city.$model"
                :state="validateState('city')"
              ></b-form-input>
            </div>
          </div>
          <div class="form-group row mb-3">
            <label for="" class="col-sm-3 col-form-label">State/Region</label>
            <div class="col-sm-9">
              <b-form-input
                type="text"
                v-model.trim="$v.form.province.$model"
                :state="validateState('province')"
              ></b-form-input>
            </div>
          </div>
          <div class="form-group row mb-3">
            <label for="" class="col-sm-3 col-form-label">Country</label>
            <div class="col-sm-9">
              <b-form-select
                v-model="form.country"
                :options="negara"
                :state="validateState('country')"
              ></b-form-select>
            </div>
          </div>
          <div class="form-group row mb-3">
            <label for="" class="col-sm-3 col-form-label">Phone Number</label>
            <div class="col-sm-3">
              <b-input-group class="mb-2 mr-sm-2 mb-sm-0">
                <template #prepend>
                  <b-input-group-text
                    ><i class="fa fa-plus"></i
                  ></b-input-group-text>
                </template>
                <b-form-input
                  type="number"
                  v-model="form.phone_cc"
                  v-model.trim="$v.form.phone_cc.$model"
                  :state="validateState('phone_cc')"
                ></b-form-input>
              </b-input-group>
            </div>
            <div class="col-sm-6">
              <b-form-input
                type="number"
                v-model="form.phone"
                v-model.trim="$v.form.phone.$model"
                :state="validateState('phone')"
              ></b-form-input>
            </div>
          </div>

          <div class="form-group row mb-3">
            <div class="col-sm-9 offset-3">
              <button class="btn btn-success">Save Changes</button> &nbsp;
              <button
                class="btn btn-default"
                type="button"
                @click="is_edit = false"
              >
                <i class="fa fa-angle-double-left"></i> back
              </button>
            </div>
          </div>
        </form>
      </template>
    </RWCard>
  </div>
</template>

<script>
import country from "@/views/pages/product/domain/management/contact/actions/country.json";
import RWCard from "@/views/partials/content/Portlet.vue";
import { validationMixin } from "vuelidate";
import { email, minLength, required } from "vuelidate/lib/validators";
import { contactMixin } from "@/common/mixins/product/domain/contact-mixin.js";
import { loading, ApiURL, notif } from "@/common/mixins/general.js";

export default {
  mixins: [contactMixin, validationMixin, loading, ApiURL, notif],
  components: { RWCard },
  props: {
    DomainID: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      selected: "",
      is_edit: false, // belum memilih contact edit
      negara: country,
      form: {}
    };
  },
  validations: {
    form: {
      fax: { minLength: minLength(1) },
      city: { minLength: minLength(3) },
      fname: { minLength: minLength(3) },
      lname: { minLength: minLength(2) },
      email: { email, minLength: minLength(5) },
      phone: { minLength: minLength(2) },
      fax_cc: { minLength: minLength(1) },
      company: { minLength: minLength(2) },
      address: { minLength: minLength(5) },
      country: { required },
      address2: { minLength: minLength(5) },
      address3: { minLength: minLength(5) },
      province: { minLength: minLength(3) },
      phone_cc: { minLength: minLength(1) },
      postal_code: { minLength: minLength(5) }
    }
  },
  methods: {
    on_submit: function() {
      if (this.selected === "") {
        this.mx_swal("You haven't selected a contact");
      } else {
        this.get_row_contact();
        this.is_edit = true;
      }
    },
    /**
     * ---------------------------------------------------
     * get contact from all contact where id = selected
     * ---------------------------------------------------
     */
    get_row_contact() {
      let contacts = this.get_contact; // from coantact mixin
      for (var i in contacts) {
        if (contacts[i]["contactid"] === this.selected) {
          this.form = contacts[i];
        }
      }

      // additional script
      let split = this.form.phone.split(".");
      this.form.phone_cc = split[0];
      this.form.phone = split[1];
      delete this.form.fax;
    },

    /**
     * ---------------------------------------------------
     * check error validasi form vuelidate
     * ---------------------------------------------------
     */
    validateState(name) {
      const { $dirty, $error } = this.$v.form[name];
      return $dirty ? !$error : null;
    },

    /**
     * ---------------------------------------------------
     * proses update contact whois
     * ---------------------------------------------------
     */
    on_submit_edit: function() {
      this.$v.form.$touch();
      if (this.$v.form.$anyError) {
        return;
      }

      this.mx_loading();
      this.xhr_domain_put("contact", this.form, this.DomainID)
        .then(({ data }) => {
          this.mx_swal(data.message, "", "success");
          this.$emit("changeAction", "Change Contact");
        })
        .catch(({ response }) => {
          this.mx_swal(response.data.message);
        })
        .finally(() => {
          this.mx_loading(false);
        });
    }
  }
};
</script>
