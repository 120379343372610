<template>
  <div class="row">
    <div class="col-sm-3">
      <RWCard title="Departement" :bodyFit="true">
        <template #body>
          <div
            class="list-group list-group-flush"
            v-for="(divisi, index) in departement"
            :key="index"
          >
            <a
              class="list-group-item list-group-item-action"
              :class="selected.departement === divisi ? 'active' : ''"
              @click="select_departement(divisi)"
            >
              {{ divisi }}
            </a>
          </div>
        </template>
      </RWCard>

      <button class="btn btn-info btn-block my-5" @click="manage()">
        Manage Domain Contacts
      </button>
    </div>

    <div class="col-sm-9">
      <div v-if="!selected.departement">
        <RWCard title="Contact Management">
          <template #body>
            <p>
              These contacts are currently associated with this domain name
              record.
            </p>
            <p>
              To modify, delete, create or change Association for the domain
              contacts, click on the "Manage Domain Contacts" button at the left
              of this section.
            </p>

            <ul class="ml-5 my-3">
              <li v-for="(divisi, index) in departement" :key="index">
                <span class="font-weight-bold">{{ divisi }} Contact</span> -
                {{ desc[divisi] }}
              </li>
            </ul>
          </template>
        </RWCard>
      </div>
      <div v-else>
        <RWCard :title="selected.departement">
          <template #body>
            <div class="alert alert-info">
              {{ desc[selected.departement] }}
            </div>

            <div class="row justify-content-center">
              <div class="col-sm-8">
                <form class="mt-3">
                  <div class="form-group row mb-2">
                    <label class="col-sm-4 col-4 col-form-label"
                      >Full Name</label
                    >
                    <div class="col-sm-8 col-8">
                      <span class="form-control-plaintext"
                        >: {{ xhr_contact.fname }} {{ xhr_contact.lname }}</span
                      >
                    </div>
                  </div>
                  <div class="form-group row mb-2">
                    <label class="col-sm-4 col-4 col-form-label"
                      >Company Name
                    </label>
                    <div class="col-sm-8 col-8">
                      <span class="form-control-plaintext"
                        >: {{ xhr_contact.company }}</span
                      >
                    </div>
                  </div>
                  <div class="form-group row mb-2">
                    <label class="col-sm-4 col-4 col-form-label"
                      >Email Address
                    </label>
                    <div class="col-sm-8 col-8">
                      <span class="form-control-plaintext"
                        >: {{ xhr_contact.email }}</span
                      >
                    </div>
                  </div>

                  <div class="form-group row mb-2">
                    <label class="col-sm-4 col-4 col-form-label"
                      >Address 1
                    </label>
                    <div class="col-sm-8 col-8">
                      <span class="form-control-plaintext"
                        >: {{ xhr_contact.address1 }}</span
                      >
                    </div>
                  </div>
                  <div class="form-group row mb-2">
                    <label class="col-sm-4 col-4 col-form-label"
                      >Address 2
                    </label>
                    <div class="col-sm-8 col-8">
                      <span class="form-control-plaintext"
                        >: {{ xhr_contact.address2 }}</span
                      >
                    </div>
                  </div>
                  <div class="form-group row mb-2">
                    <label class="col-sm-4 col-4 col-form-label"
                      >Address 3
                    </label>
                    <div class="col-sm-8 col-8">
                      <span class="form-control-plaintext"
                        >: {{ xhr_contact.address3 }}</span
                      >
                    </div>
                  </div>
                  <div class="form-group row mb-2">
                    <label class="col-sm-4 col-4 col-form-label"
                      >Zip Code</label
                    >
                    <div class="col-sm-8 col-8">
                      <span class="form-control-plaintext"
                        >: {{ xhr_contact.postcode }}</span
                      >
                    </div>
                  </div>
                  <div class="form-group row mb-2">
                    <label class="col-sm-4 col-4 col-form-label">City</label>
                    <div class="col-sm-8 col-8">
                      <span class="form-control-plaintext"
                        >: {{ xhr_contact.city }}</span
                      >
                    </div>
                  </div>
                  <div class="form-group row mb-2">
                    <label class="col-sm-4 col-4 col-form-label"
                      >State/Region</label
                    >
                    <div class="col-sm-8 col-8">
                      <span class="form-control-plaintext"
                        >: {{ xhr_contact.state }}</span
                      >
                    </div>
                  </div>
                  <div class="form-group row mb-2">
                    <label class="col-sm-4 col-4 col-form-label">Country</label>
                    <div class="col-sm-8 col-8">
                      <span class="form-control-plaintext"
                        >: {{ xhr_contact.country }}</span
                      >
                    </div>
                  </div>
                  <div class="form-group row mb-2">
                    <label class="col-sm-4 col-4 col-form-label"
                      >Phone Number</label
                    >
                    <div class="col-sm-8 col-8">
                      <span class="form-control-plaintext"
                        >: {{ xhr_contact.phonenumber }}</span
                      >
                    </div>
                  </div>
                  <div class="form-group row mb-2">
                    <label class="col-sm-4 col-4 col-form-label"
                      >Fax Number</label
                    >
                    <div class="col-sm-8 col-8">
                      <span class="form-control-plaintext"
                        >: {{ xhr_contact.fax }}</span
                      >
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </template>
        </RWCard>
      </div>
    </div>
  </div>
</template>

<script>
import RWCard from "@/views/partials/content/Portlet.vue";
import { ApiURL, loading } from "@/common/mixins/general.js";
import { changeContactComponent } from "@/store/rw/product.domain.module.js";

export default {
  name: "domainContactInfo",
  mixins: [ApiURL, loading],
  props: {
    DomainID: {
      type: String,
      required: true
    }
  },
  components: {
    RWCard
  },
  data() {
    return {
      departement: ["Registrant", "Administrator", "Technical", "Billing"],
      desc: {
        Registrant: "Is the legal owner or domain license holder",
        Administrator: "Is the Domain Name Manager",
        Technical: "Manager of technical issues",
        Billing: "Is authorized to receive renewal invoices"
      },
      selected: {
        departement: false
      },

      /**
       * -------------------------------------------------
       * tampung dari request Api
       * -------------------------------------------------
       */
      xhr_contact: {}
    };
  },
  methods: {
    select_departement(name) {
      this.mx_loading();
      this.selected.departement = name;

      let lowerName = name.toLowerCase();
      if (lowerName === "technical") {
        lowerName = "tech";
      }
      if (lowerName === "administrator") {
        lowerName = "admin";
      }
      this.get_contact(lowerName);
    },
    manage() {
      this.$store.dispatch(changeContactComponent, "ContactAction");
    },

    /**
     * -------------------------------------------------
     * request contact domain whois per departement / divisi
     * -------------------------------------------------
     */
    get_contact(departement) {
      // ApiService.setHeader();
      // ApiService.get(
      //   this.api.product.domain.contacttype +
      //     "/" +
      //     this.DomainID +
      //     "/" +
      //     departement
      // )
      this.xhr_domain("contacttype", this.DomainID + "/" + departement)
        .then(({ data }) => {
          console.log(data);
          this.xhr_contact = data.data.contact;
        })
        .catch(({ response }) => {
          console.log(response);
        })
        .finally(() => this.mx_loading(false));
    }
  },
  mounted() {}
};
</script>

<style scoped>
.list-group-flush > .list-group-item {
  cursor: pointer;
  border-width: 0 0 1px;
}
</style>
