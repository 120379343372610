<template>
  <div>
    <RWCard title="Social Media Connect">
      <template v-slot:body>
        <div class="alert alert-info">
          Jadikan media sosial, channel youtube, atau lapak marketplace Anda
          sebagai homepage untuk {{ DomainName }}.
        </div>
        <div v-if="!is_choice">
          <p class="lead mb-4 font-weight-bold">
            Silahkan pilih platform yang ingin Anda jadikan sebagai homepage :
          </p>
          <div class="row">
            <div class="col-sm-4">
              <RWCard title="Media Sosial" class="shadow" :bodyFit="true">
                <template #body>
                  <p class="text-center mt-3">
                    Pilih Media Sosial yang Anda gunakan:
                  </p>
                  <div v-if="loading" class="text-center mb-3">
                    <b-skeleton class="mx-auto" width="70%"></b-skeleton>
                    <b-skeleton class="mx-auto" width="70%"></b-skeleton>
                    <b-skeleton class="mx-auto" width="70%"></b-skeleton>
                    <b-skeleton class="mx-auto" width="70%"></b-skeleton>
                    <b-skeleton class="mx-auto" width="70%"></b-skeleton>
                    <b-skeleton class="mx-auto" width="70%"></b-skeleton>
                  </div>
                  <div class="list-group list-group-flush" v-else>
                    <a
                      href="javascript:void(0)"
                      @click="choice_brand('socialMedia', index)"
                      class="list-group-item list-group-item-action"
                      v-for="(sosmed, index) in socialMedia"
                      :key="index"
                    >
                      <i :class="sosmed.icon"></i> {{ sosmed.name }}
                    </a>
                  </div>
                </template>
              </RWCard>
            </div>

            <div class="col-sm-4">
              <RWCard title="Marketplace" class="shadow" :bodyFit="true">
                <template v-slot:body>
                  <p class="text-center mt-3">
                    Pilih marketplace yang Anda gunakan:
                  </p>
                  <div v-if="loading" class="text-center mb-3">
                    <b-skeleton class="mx-auto" width="70%"></b-skeleton>
                    <b-skeleton class="mx-auto" width="70%"></b-skeleton>
                    <b-skeleton class="mx-auto" width="70%"></b-skeleton>
                    <b-skeleton class="mx-auto" width="70%"></b-skeleton>
                    <b-skeleton class="mx-auto" width="70%"></b-skeleton>
                    <b-skeleton class="mx-auto" width="70%"></b-skeleton>
                  </div>
                  <div class="list-group list-group-flush" v-else>
                    <a
                      href="javascript:void(0)"
                      @click="choice_brand('marketPlace', index)"
                      class="list-group-item list-group-item-action"
                      v-for="(shop, index) in marketPlace"
                      :key="index"
                    >
                      <i :class="shop.icon"></i> {{ shop.name }}
                    </a>
                  </div>
                </template>
              </RWCard>
            </div>

            <div class="col-sm-4">
              <RWCard title="Lainnya" class="shadow">
                <template v-slot:body>
                  <p>Jadikan apapun halaman online Anda sebagai homepage:</p>
                  <div v-if="loading" class="text-center mb-3">
                    <b-skeleton class="mx-auto" width="70%"></b-skeleton>
                    <b-skeleton class="mx-auto" width="70%"></b-skeleton>
                  </div>
                  <div class="list-group" v-else>
                    <a
                      hhref="javascript:void(0)"
                      @click="choice_brand('other', index)"
                      class="list-group-item list-group-item-action"
                      v-for="(lain, index) in other"
                      :key="index"
                    >
                      <i :class="lain.icon"></i> {{ lain.name }}
                    </a>
                  </div>
                </template>
              </RWCard>
            </div>
          </div>
        </div>

        <!-- jika memilih -->
        <div v-else>
          <p class="lead mb-4">
            Masukkan alamat
            <span class="font-weight-bold">{{ get_brand }}</span> yang ingin
            Anda jadikan sebagai homepage untuk domain
            <span class="font-weight-bold">{{ DomainName }}</span
            >:
          </p>
          <form @submit.stop.prevent="sambungkan">
            <div class="form-group row my-3 justify-content-center">
              <div class="col-sm-6">
                <div class="input-group">
                  <div class="input-group-prepend">
                    <div class="input-group-text">{{ get_url }}</div>
                  </div>
                  <b-form-input
                    type="text"
                    v-model.trim="$v.form.endpoint.$model"
                    :state="validateState('endpoint')"
                  ></b-form-input>
                </div>
              </div>
            </div>
            <div class="row justify-content-center">
              <div class="col-sm-6">
                <button class="btn btn-success btn-block">
                  Sambungkan ke domain {{ DomainName }}
                </button>
              </div>
            </div>
          </form>
        </div>
      </template>
      <template v-slot:foot>
        <div v-if="!is_choice">
          <a @click="$router.go(-1)" class="rw-cursor-pointer">
            <i class="fa fa-angle-double-left"></i> Back
          </a>
        </div>
        <div v-else>
          <a @click="is_choice = false" class="rw-cursor-pointer">
            <i class="fa fa-angle-double-left"></i> Back
          </a>
        </div>
      </template>
    </RWCard>
  </div>
</template>

<script>
import { validationMixin } from "vuelidate";
import { minLength, required } from "vuelidate/lib/validators";

import RWCard from "@/views/partials/content/Portlet.vue";
import config from "@/common/config/product/domain.config.json";
import { loading, ApiURL, notif } from "@/common/mixins/general.js";
export default {
  components: { RWCard },
  mixins: [loading, ApiURL, notif, validationMixin],
  props: {
    DomainID: {
      type: String,
      required: true
    },
    DomainName: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      form: {
        hash: "",
        endpoint: ""
      },
      loading: true,
      is_choice: false,
      choiceBrand: null,
      choicePlatform: null, // ex. socialMedia, marketPlace, other

      /**
       * -------------------------------------------
       * data untuk sosial media redirect
       * -------------------------------------------
       */
      socialMedia: config.sosmed_redirect.sosmed,
      marketPlace: config.sosmed_redirect.market_place,
      other: config.sosmed_redirect.other
    };
  },
  validations: {
    form: {
      endpoint: { required, minLength: minLength(3) }
    }
  },
  methods: {
    choice_brand(platform, brandName) {
      this.choicePlatform = platform;
      this.choiceBrand = brandName;
      this.is_choice = true;
    },

    /**
     * ------------------------------------------------
     * get status sosmedredirect / get hash
     * ------------------------------------------------
     */
    get_sosmed: function() {
      this.xhr_domain("sosmedredirect", this.DomainID)
        .then(({ data }) => {
          this.form.hash = data.data.hash_social_redirect;
        })
        .finally(() => {
          this.loading = false;
        });
    },

    /**
     * ------------------------------------------------
     * check validated form
     * ------------------------------------------------
     */
    validateState(name) {
      const { $dirty, $error } = this.$v.form[name];
      return $dirty ? !$error : null;
    },

    /**
     * ------------------------------------------------
     * menyambungkan / submit data
     * ------------------------------------------------
     */
    sambungkan: function() {
      this.$v.form.$touch();
      if (this.$v.form.$anyError) {
        return;
      }

      if (this.form.hash === "" || this.form.endpoint === "") {
        this.mx_swal("you have not entered the url");
      } else {
        this.mx_loading();
        let data = {
          target: this.get_url + this.form.endpoint
        };
        this.xhr_domain_put("sosmedredirect", data, this.form.hash)
          .then(({ data }) => {
            this.mx_swal(data.message, "", "success");
            this.is_choice = false;
            this.form.endpoint = "";
          })
          .catch(({ response }) => {
            this.mx_swal(response.data.message);
          })
          .finally(() => {
            this.mx_loading(false);
            this.loading = false;
          });
      }
    }
  },
  computed: {
    get_brand() {
      return this[this.choicePlatform][this.choiceBrand].name;
    },
    get_url() {
      return this[this.choicePlatform][this.choiceBrand].url;
    }
  },
  mounted() {
    this.get_sosmed();
  }
};
</script>
