<template>
  <div class="row justify-content-center">
    <div class="col-sm-6">
      <div class="alert alert-info">Add Record for: {{ DomainName }}</div>
      <form @submit="on_submit">
        <div class="form-group row my-3">
          <label class="col-sm-3 col-form-label">Domain</label>
          <div class="col-sm-9">
            <div class="input-group">
              <input type="text" class="form-control" v-model="form.record" />
              <div class="input-group-prepend">
                <div class="input-group-text">{{ DomainName }}</div>
              </div>
            </div>
          </div>
        </div>
        <div class="form-group row my-3">
          <label class="col-sm-3 col-form-label">TTL</label>
          <div class="col-sm-9">
            <input type="number" class="form-control" v-model="form.ttl" />
          </div>
        </div>
        <div class="form-group row my-3">
          <label class="col-sm-3 col-form-label">Record Type</label>
          <div class="col-sm-9">
            <b-form-select
              v-model="selected"
              :options="recordType"
              @change="change_record()"
            ></b-form-select>
          </div>
        </div>

        <div class="form-group row my-3" v-if="selected === 'A'">
          <label class="col-sm-3 col-form-label">IP or Hostname</label>
          <div class="col-sm-9">
            <input type="text" class="form-control" v-model="form.hostname" />
          </div>
        </div>

        <div class="form-group row my-3" v-if="selected === 'AAAA'">
          <label class="col-sm-3 col-form-label">IPv6 Address</label>
          <div class="col-sm-9">
            <input type="text" class="form-control" v-model="form.hostname" />
          </div>
        </div>

        <div
          class="form-group row my-3"
          v-if="
            selected === 'CNAME' ||
              selected === 'MX' ||
              selected === 'NS' ||
              selected === 'SRV'
          "
        >
          <label class="col-sm-3 col-form-label">Hostname</label>
          <div class="col-sm-9">
            <input type="text" class="form-control" v-model="form.hostname" />
          </div>
        </div>

        <div
          class="form-group row my-3"
          v-if="selected === 'SRV' || selected === 'MX'"
        >
          <label class="col-sm-3 col-form-label">Priority</label>
          <div class="col-sm-9">
            <input type="text" class="form-control" v-model="form.priority" />
          </div>
        </div>

        <div class="form-group row my-3" v-if="selected === 'SRV'">
          <label class="col-sm-3 col-form-label">Weight</label>
          <div class="col-sm-9">
            <input type="text" class="form-control" v-model="form.weight" />
          </div>
        </div>

        <div class="form-group row my-3" v-if="selected === 'SRV'">
          <label class="col-sm-3 col-form-label">Port</label>
          <div class="col-sm-9">
            <input type="text" class="form-control" v-model="form.port" />
          </div>
        </div>

        <div class="form-group row my-3" v-if="selected === 'TXT'">
          <label class="col-sm-3 col-form-label">Text Information</label>
          <div class="col-sm-9">
            <textarea class="form-control" v-model="form.hostname"></textarea>
          </div>
        </div>

        <div class="row">
          <div class="col-sm-10 offset-3">
            <button class="btn btn-success btn-sm" type="submit">
              Add Record
            </button>
            &nbsp;
            <button
              class="btn btn-secondary btn-sm"
              @click="change_component('DnsList')"
            >
              <i class="fa fa-angle-double-left"></i> Back
            </button>
          </div>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import { dnsMixin } from "@/common/mixins/product/domain/dns/dns-mixin.js";
import { ApiURL, notif, loading } from "@/common/mixins/general.js";

export default {
  mixins: [dnsMixin, notif, ApiURL, loading],
  props: {
    DomainName: {
      type: String,
      required: true
    },
    DomainID: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      selected: null,
      recordType: [
        { value: null, text: "Please select an option" },
        { value: "A", text: "A" },
        { value: "AAAA", text: "AAAA" },
        { value: "CNAME", text: "CNAME" },
        { value: "MX", text: "MX" },
        { value: "NS", text: "NS" },
        { value: "SRV", text: "SRV" },
        { value: "TXT", text: "TXT" }
      ],
      form: {
        record: "",
        ttl: 86400,
        hostname: "", // destination
        priority: "",
        wight: "",
        port: ""
      }
    };
  },
  methods: {
    change_record() {
      this.form.hostname = ""; // destination
      this.form.priority = "";
      this.form.weight = "";
      this.form.port = "";
    },

    /**
     * -----------------------------------------------
     * proses submit / create DNS
     * -----------------------------------------------
     */
    on_submit(e) {
      e.preventDefault();

      const post = {
        record: this.form.record,
        type: this.selected,
        ttl: this.form.ttl,
        destination: this.form.hostname,
        priority: this.form.priority,
        weight: this.form.weight,
        port: this.form.port
      };

      this.mx_loading();
      this.xhr_domain_post("dnsmanagement", post, this.DomainID)
        .then(({ data }) => {
          this.mx_swal(data.message, "", "success");
          this.change_component("DnsList");
        })
        .catch(({ response }) => {
          this.mx_swal(response.data.message);
        })
        .finally(() => {
          this.mx_loading(false);
        });
    }
  }
};
</script>
